<template>
    <div class="allegro-auth">
        <b-row v-if="tokens.length > 0">
            <b-col md="8">
                <wit-select
                    class="text-nowrap"
                    v-model="token"
                    placeholder="Select Account"
                    :options="tokens"
                    id="token-multiselect"
                    @input="emitInput"
                    :disabled="disabled"
                    label="owner"
                    :filterBy="filterBy"
                >
                    <div slot="option" slot-scope="option">
                        <span>{{ `${option.owner.name} (${option.owner.email})` }}</span>
                    </div>
                    <div slot="selected-option" slot-scope="option">
                        <span>{{ `${option.owner.name} (${option.owner.email})` }}</span>
                    </div>
                </wit-select>
            </b-col>
            <b-col md="8">
                <div class="allegro-reauthorize allegro-auth-note">
                    or
                    <b-btn variant="primary" size="sm" :disabled="disabled" @click="authorize"
                        >Click here to authorize</b-btn
                    >to use a different Account
                </div>
                <Feedback
                    :state="validateRef('token')"
                    invalid="This field is required"
                    valid="Token is valid"
                ></Feedback>
            </b-col>
        </b-row>
        <b-row v-if="tokens.length === 0">
            <b-col md="8">
                <span class="allegro-auth-note">
                    <b-btn variant="primary" size="sm" :disabled="disabled" @click="authorize"
                        >Click here to authorize</b-btn
                    >
                    <br />You do not seem to have given us access to Allegro. You have to authorize first to select an
                    authorized account.
                </span>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'

import Feedback from '@/components/Feedback.vue'

export default {
    components: {Feedback},
    props: {
        value: {
            type: String | null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        projectId: {
            type: String | null,
        },
        step: {
            type: Object | null,
        },
    },
    mixins: [validationMixin],
    validations: {
        token: {required},
    },
    watch: {
        value() {
            this.token = this.tokens.find((el) => el.id === this.value)
        },
        async projectId() {
            await this.$store.dispatch('token/fetchTokens', this.projectId)
            this.token = this.tokens.find((el) => el.id === this.value)
        },
    },
    async created() {
        await this.$store.dispatch('token/fetchTokens', this.projectId)

        this.token = this.tokens.find((el) => el.id === this.value)
    },
    data() {
        return {
            token: null,
            loadingInterval: null,
        }
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
            tokens: 'token/allegroTokens',
        }),
    },
    methods: {
        emitInput() {
            if (this.token) {
                this.$emit('input', this.token.id)
            } else {
                this.$emit('input', null)
            }
        },
        authorize() {
            this.$store.commit('loading/PROCESSING', `Authorizing...`)
            this.loadingInterval = setInterval(() => this.$store.commit('loading/PROCESSING', `Authorizing...`), 10000)
            const options = {
                userId: this.$auth.user().id,
                projectId: this.projectId ? this.projectId : this.activeProject.id,
            }
            this.$store
                .dispatch(`allegro/upsertToken`, options)
                .then(async (response) => {
                    await this.$store.dispatch('token/fetchTokens', this.projectId)

                    if (this.tokens.length) {
                        this.token = this.tokens[0]
                        this.emitInput()
                    }

                    clearInterval(this.loadingInterval)
                    this.loadingInterval = null

                    this.$store.commit('loading/PROCESSED')
                })
                .catch((exception) => {
                    clearInterval(this.loadingInterval)
                    this.loadingInterval = null

                    this.$store.commit('loading/PROCESSED')
                    this.$errorHandler.report(exception, 'Could not upsert allegro token')
                })
        },
        validateRef(ref) {
            const keys = ref.split('.')
            const validateField = keys.reduce((a, c) => {
                return a[c]
            }, this.$v)

            return (this.step && this.step.checked) || validateField.$dirty ? !validateField.$invalid : null
        },
        filterBy(option, label, search) {
            return `${option.owner.name} (${option.owner.email})`.toLowerCase().indexOf(search.toLowerCase()) > -1
        },
    },
}
</script>

<style lang="scss">
.allegro-reauthorize {
    opacity: 0.8;
    margin-top: 5px;
}
.allegro-auth {
    .allegro-auth-note {
        font-weight: 600;
        text-transform: uppercase;
        font-size: 0.7rem;
        img {
            width: 150px;
        }

        img.pointer-disabled {
            filter: grayscale(70%);
        }
    }
}
</style>
